// consumer_excessive_trainTicket_seat 火车票坐席分析
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://192.168.0.21:8765/doc.html#/haolv-consumer/train-ticket-excessive-controller/selectSeatUsingPOST
const consumer_excessive_trainTicket_seat = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/excessive/trainTicket/seat',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_excessive_trainTicket_seat;