import echarts from "echarts";
import consumer_excessive_trainTicket_consumptionTotal from '@/lib/data-service/haolv-default/consumer_excessive_trainTicket_consumptionTotal'
import consumer_excessive_trainTicket_month from '@/lib/data-service/haolv-default/consumer_excessive_trainTicket_month'
import consumer_excessive_trainTicket_seat from '@/lib/data-service/haolv-default/consumer_excessive_trainTicket_seat'
import consumer_excessive_trainTicket_dept from '@/lib/data-service/haolv-default/consumer_excessive_trainTicket_dept'
import consumer_excessive_trainTicket_refundChange from '@/lib/data-service/haolv-default/consumer_excessive_trainTicket_refundChange'
import consumer_department_getDepartmentList from '@/lib/data-service/haolv-default/consumer_department_getDepartmentList' // 获取部门list
import moment from "moment";
export default {
    data () {
        return {
            params: {
                deptId: '',
                deptName: '',
                startTime: '',
                endTime: '',
                mold: '',
                type: '',
                dateArr: [],
                deptIdArr: [''],
                timeList: []
            },
            totalInfo: {},
            options: [],
            monthType: '月度',
            barOption: {},
            trainType: '高铁列车', // 车次类型:1.高铁、2.动车
            pieOption: {},
            barDeptOption: {},
            barDeptBuyOption: {},
            barRefundOption: {}, // 退改签情况
            pickerOptions: {
                disabledDate: this.disabledDate,
                onPick: this.onPick
            },
            timeOptionRange: null,
        }
    },
    components: {},
    created () {},
    mounted () {

    },
    activated () {
        this.getDepartmentList()
        this.setTimeList()
        this.getTrainTicketMonth()
        this.getConsumptionTotal()
        this.getTrainTicketSeat()
        this.grtTrainTicketDeptMoney()
        this.grtTrainTicketDeptPage()
        this.getTrainTicketRefundChange()
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        // 获取部门列表
        getDepartmentList() {
            consumer_department_getDepartmentList().then(res => {
                let departmentList = res.datas.departmentListResults
                departmentList.unshift({deptName: '全部', deptId: ''})
                this.options = departmentList
            })
        },
        changeDeptId(val) {
            console.log(val)
            let _this = this
            function getDeptName(list, activeId) {
                list.forEach(value => {
                    if (value.deptId === activeId) {
                        _this.params.deptName = value.deptName
                    }
                    if (value.childrenList && value.childrenList.length > 0) {
                        getDeptName(value.childrenList, activeId)
                    }
                })
            }
            if (val.length === 0) {
                this.params.deptId = ''
                this.params.deptName = ''
            } else {
                this.params.deptId = val[0]
                getDeptName(this.options, val[0])
            }
        },
        setTimeList() {
            if (this.params.timeList.length === 0) {
                let now = moment()
                let year = now.year()
                let month = now.month()
                console.log(year, month)
                let timeList = []
                for (let i = 1, l = month + 2; i < l; i++) {
                    let num = i > 9 ? i.toString() : '0' + i
                    let item = {
                        dataTime: `${year}-${num}`,
                        dataMonth: num
                    }
                    timeList.push(item)
                }
                console.log(timeList)
                this.params.timeList = timeList
            }
        },
        disabledDate(time) {
            // 获取选中时间
            let timeOptionRange = this.timeOptionRange
            if (timeOptionRange) {
                // 如果有获取时间
                let year = moment(timeOptionRange).year()
                let timeYear = moment(time).year()
                return year !== timeYear
            } else {
                // 没有获取过时间，则全部都过
                return false
            }
        },
        onPick({maxDate, minDate}) {
            // 当选中了第一个日期还没选第二个
            // 只选中一个的时候自动赋值给minDate，当选中第二个后组件自动匹配，将大小日期分别赋值给maxDate、minDate
            if (minDate && !maxDate) {
                this.timeOptionRange = minDate;
            }
            // 如果有maxDate属性，表示2个日期都选择了，则重置timeOptionRange
            if (maxDate) {
                this.timeOptionRange = null;
            }
        },
        changeOrderDate(val) {
            console.log(val)
            if (val === '' || val === null) {
                this.params.startTime = ''
                this.params.endTime = ''
                this.params.timeList = []
                this.setTimeList()
            } else {
                let bigMonth = ['01', '03', '05', '07', '08', '10', '12']
                let currentMonth = val[0].split('-')[1]

                this.params.startTime = val[0] + '-01'
                this.params.endTime = `${val[1]}-${bigMonth.indexOf(currentMonth) > -1 ? '31' : '30'}`

                let year = this.params.startTime.split('-')[0]
                let startMonth = parseInt(this.params.startTime.split('-')[1])
                let endMonth = parseInt(this.params.endTime.split('-')[1])
                let timeList = []
                for (let i = startMonth, l = endMonth + 1; i < l; i++) {
                    let num = i > 9 ? i.toString() : '0' + i
                    let item = {
                        dataTime: `${year}-${num}`,
                        dataMonth: num
                    }
                    timeList.push(item)
                }
                console.log(timeList)
                this.params.timeList = timeList
            }
        },
        search() {
            this.getTrainTicketMonth()
            this.getConsumptionTotal()
            this.getTrainTicketSeat()
            this.grtTrainTicketDeptMoney()
            this.grtTrainTicketDeptPage()
            this.getTrainTicketRefundChange()
        },
        // 获取总报表
        getConsumptionTotal() {
            consumer_excessive_trainTicket_consumptionTotal(this.params).then(res => {
                this.totalInfo = res.trainTicketReportFormsVO
            })
        },
        // 获取逐月消费
        getTrainTicketMonth() {
            let monthArr = []
            consumer_excessive_trainTicket_month(this.params).then(res => {
                // res.data.trainTicketReportFormsList
                let month = res.trainTicketReportFormsList
                if (month === null) {
                    return
                }
                month.forEach(value => {
                    monthArr.push(value.money)
                })
                this.barOption = {
                    grid: {
                        top:36,
                        bottom: 59,
                        right: 32
                    },
                    xAxis: {
                        // type: 'category',
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7'
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#666'
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: '金额',
                        nameTextStyle: {
                            color: '#666'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            interval: 0,
                            color: '#666'
                        },
                        splitLine: {
                            show: false
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    series: [{
                        data: monthArr,
                        type: 'bar',
                        barWidth: 10,
                        showBackground: false,
                        backgroundStyle: {
                            color: 'rgba(220, 220, 220, 0.8)'
                        },
                        label: {
                            show: true,
                            color: '#666666',
                            formatter: '￥{c}',
                            position: 'top'
                        },
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0, color: '#FF5A00'},
                                    {offset: 0.5, color: '#FFE3D4'},
                                    {offset: 1, color: '#FFFFFF'}
                                ]
                            )
                        }
                    }]
                }
            })
        },
        // 坐席分析
        getTrainTicketSeat() {
            let params = Object.assign({}, this.params)
            params.mold = this.trainType === '高铁列车' ? 1 : 2
            consumer_excessive_trainTicket_seat(params).then(res => {
                let data = res.trainTicketReportFormsList
                data.forEach(value => {
                    value.name = value.seatName
                    value.value = value.votes
                })

                this.pieOption = {
                    series:[{
                        type: 'pie',
                        data: data,
                        color: ['rgba(255,111,33,1)', 'rgba(255,111,33,0.8)', 'rgba(255,111,33,0.62)', 'rgba(255,111,33,0.5)', 'rgba(255,111,33,0.3)', 'rgba(255,111,33,0.2)', 'rgba(255,111,33,0.1)'],
                        label: {
                            formatter: '{a|{b}}{b|{c}}{a|张}',
                            rich: {
                                a: {
                                    color: '#666666'
                                },
                                b: {
                                    color: '#FF6F21'
                                },
                            }
                        },
                        labelLine: {
                            smooth: false,
                            lineStyle: {
                                color: '#707070',
                                type: 'dotted'
                            }
                        }
                    }]
                }
            })
        },
        // 改变逐月消费的车辆类型时触发
        changeTrainType(val) {
            this.getTrainTicketSeat()
        },
        // 获取消费前五的部门
        grtTrainTicketDeptMoney() {
            let params = Object.assign({}, this.params)
            params.type = 2
            consumer_excessive_trainTicket_dept(params).then(res => {
                let data = res.trainTicketReportFormsList
                console.log(data)
                let deptList = []
                let moneyList = []
                data.forEach(value => {
                    deptList.push(value ? value.deptName : '')
                    moneyList.push(value ? value.money : 0)
                })

                this.barDeptOption = {
                    grid: {
                        top:36,
                        bottom: 59,
                        right: 32
                    },
                    xAxis: {
                        // type: 'category',
                        data: deptList,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7'
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#666',
                            formatter: (value) => {
                                let res = value;
                                if(res.length > 5) {
                                    res = res.substring(0, 4) + "..";
                                }
                                return res;
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: '金额（元）',
                        nameTextStyle: {
                            color: '#666'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            interval: 0,
                            color: '#666'
                        },
                        splitLine: {
                            show: false
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    series: [{
                        data: moneyList,
                        type: 'bar',
                        barWidth: 10,
                        showBackground: false,
                        backgroundStyle: {
                            color: 'rgba(220, 220, 220, 0.8)'
                        },
                        label: {
                            show: true,
                            color: '#666666',
                            formatter: '￥{c}',
                            position: 'top'
                        },
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0, color: '#FF5A00'},
                                    {offset: 0.5, color: '#FFE3D4'},
                                    {offset: 1, color: '#FFFFFF'}
                                ]
                            )
                        }
                    }]
                }
            })
        },
        // 获取消费前五的部门的张数
        grtTrainTicketDeptPage() {
            let params = Object.assign({}, this.params)
            params.type = 1
            consumer_excessive_trainTicket_dept(params).then(res => {
                let data = res.trainTicketReportFormsList
                let deptList = []
                let votesList = []
                data.forEach(value => {
                    deptList.push(value.deptName)
                    votesList.push(value.votes)
                })

                this.barDeptBuyOption = {
                    grid: {
                        top:36,
                        bottom: 59,
                        right: 32
                    },
                    xAxis: {
                        // type: 'category',
                        data: deptList,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7'
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#666',
                            formatter: (value) => {
                                let res = value;
                                if(res.length > 5) {
                                    res = res.substring(0, 4) + "..";
                                }
                                return res;
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: '数量（张）',
                        nameTextStyle: {
                            color: '#666',
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            interval: 0,
                            color: '#666',
                        },
                        splitLine: {
                            show: false
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    series: [{
                        data: votesList,
                        type: 'bar',
                        barWidth: 10,
                        showBackground: false,
                        backgroundStyle: {
                            color: 'rgba(220, 220, 220, 0.8)'
                        },
                        label: {
                            show: true,
                            color: '#666666',
                            formatter: '{c}',
                            position: 'top',
                        },
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0, color: '#FF5A00'},
                                    {offset: 0.5, color: '#FFE3D4'},
                                    {offset: 1, color: '#FFFFFF'}
                                ]
                            )
                        }
                    }]
                }
            })
        },
        // 获取退改情况
        getTrainTicketRefundChange() {
            consumer_excessive_trainTicket_refundChange(this.params).then(res => {
                let date = res.trainTicketReportFormsVO
                let pageList = [date.votes, date.refundVotes, date.changeVotes]
                let percentList = [parseFloat(date.refundRate), parseFloat(date.changeRate)]

                this.barRefundOption = {
                    toolbox: {
                        top: 0,
                        feature: {
                            //点击图表可直接将柱形图与折线图进行切换
                            magicType: {
                                show: false,
                                type: ['line', 'bar']
                            },
                        }
                    },
                    grid: {
                        top:36,
                        bottom: 59,
                        right: 48
                    },
                    xAxis: [{
                        type: 'category',
                        boundaryGap: true,
                        data: ['出票张数', '退票张数', '改签张数'],
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7'
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#666'
                        }
                    }, {
                        type: 'category',
                        boundaryGap: true,
                        data: ['退票率', '改签率'],
                    }],
                    yAxis: [{
                        type: 'value',
                        name: '金额',
                        nameTextStyle: {
                            color: '#666'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            interval: 0,
                            color: '#666'
                        },
                        splitLine: {
                            show: false
                        }
                    },{
                        type: 'value',
                        name: '比率',
                        nameTextStyle: {
                            color: '#666'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            interval: 0,
                            formatter: '{value} %'
                        }
                    }],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    series: [{
                        data: pageList,
                        type: 'bar',
                        barWidth: 10,
                        showBackground: false,
                        backgroundStyle: {
                            color: 'rgba(220, 220, 220, 0.8)'
                        },
                        label: {
                            show: true,
                            color: '#666666',
                            formatter: '￥{c}',
                            position: 'top'
                        },
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0, color: '#FF5A00'},
                                    {offset: 0.5, color: '#FFE3D4'},
                                    {offset: 1, color: '#FFFFFF'}
                                ]
                            )
                        }
                    }, {
                        data: percentList,
                        xAxisIndex: 1,
                        yAxisIndex: 1,    //这里要设置哪个y轴，默认是最左边的是0，然后1，2顺序来
                        type: 'line',
                        itemStyle: {
                            color: '#FF5A00'
                        }
                    }]
                }
            })
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/